import { Component, Input } from '@angular/core';

@Component({
  selector: 'kb-page',
  templateUrl: './page.component.html'
})
export class PageComponent {

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() embedded: boolean = false;

}
