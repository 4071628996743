<!-- PAGE HEADER -->
<div data-testid="page.header" class="page-header w-full flex gap-2">

  <!-- PAGE TITLE -->
  <div data-testid="page.header.title" class="page-header-title-container flex-auto">

    <ng-content select="kb-page-title"></ng-content>

  </div> <!-- /page-header-title-container -->

  <!-- PAGE ACTIONS -->
  <div data-testid="page.header.actions" class="page-header-actions-container flex-initial">

    <ng-content select="kb-page-actions"></ng-content>

  </div> <!-- /page-header-actions-container -->

</div>