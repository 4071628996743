import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PageActionsModule } from './actions/page-actions.module';
import { PageContentModule } from './content/page-content.module';
import { PageHeaderModule } from './header/page-header.module';
import { PageComponent } from './page.component';
import { PageTitleModule } from './title/page-title.module';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [PageComponent],
  exports: [
    PageComponent,
    PageActionsModule,
    PageTitleModule,
    PageHeaderModule,
    PageContentModule
  ]
})
export class PageModule { }
