<!-- PAGE CONTAINER -->
<div data-testid="page.container" class="page-container w-full" [ngClass]="embedded === false ? 'py-6 sm:py-12' : ''">

  <!-- PAGE HEADER -->
  <div data-testid="page.header.container" class="page-header-container">

    <ng-content select="kb-page-header"></ng-content>

  </div> <!-- /page-header-container -->

  <!-- PAGE CONTENT -->
  <div data-testid="page.content.container" class="page-content-container" [class.mt-4]="embedded === false">

    <ng-content select="kb-page-content"></ng-content>

  </div> <!-- /page-content-container -->

</div>